<template>
    <div class="clock-in-shift">
        <div class="top-bar">
            <p style="display:flex; align-items:center;"><i class="material-icons" style="font-size: 24px;">perm_identity</i>כניסה למשמרת</p>
            <div class="filter-btns">
                <div class="filter-btn" @click="filter = 'מלצרים'">
                    <p>מלצרים</p>
                </div>
                <div class="filter-btn" @click="filter = 'ברמנים'">
                    <p>ברמנים</p>
                </div>
                <div class="filter-btn" @click="filter = 'שונות'">
                    <p>שונות</p>
                </div>
                <div class="filter-btn" @click="filter = 'הנהלה'">
                    <p>הנהלה</p>
                </div>
            </div>

        </div>
        <div class="workers-grid">
            <template v-for="worker in filtered_workers" :key="worker.id">
                <div class="worker-box" :id="worker.id" @click="clock_in_shift(worker)">
                    <div class="worker-action">
                        <i class="material-icons" style="font-size:24px;">check_circle_outline</i>
                    </div>
                    <div class="worker-name">
                        <p>{{worker.name}}</p>
                    </div>
                    <div class="worker-image">
                        <img :src="worker.image" alt="">
                    </div>
                    <div class="worker-in-shift" v-if="get_worker_from_shift(worker) != false">
                        <p v-if="get_worker_from_shift(worker).shift.active">במשמרת</p>
                        <p style="color:var(--yellow)" v-if="!get_worker_from_shift(worker).shift.active">סיים ממשמרת</p>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import { computed, onMounted, ref } from '@vue/runtime-core';
import { projectFirestore } from '../../firebase/config';
import Swal from 'sweetalert2';
import router from '../../router';
import {is_active_shift} from '../../Methods/Shift';
import {clock_in_shift, get_worker_from_shift} from '../../Methods/Workers';
import {slide_pop_error} from '../../Methods/Msgs';
export default {
setup(){
    const workers = ref([]);
    const filter = ref(null);
    
    const filtered_workers = ref(computed(() => {
        if(!filter.value){
            return workers.value
        }
        if(filter.value == 'מלצרים'){
            return workers.value.filter(worker => worker.category == 'מלצרים')
        }
        if(filter.value == 'ברמנים'){
            return workers.value.filter(worker => worker.category == 'ברמנים')
        }
        if(filter.value == 'שונות'){
            return workers.value.filter(worker => worker.category == 'שונות')
        }
        if(filter.value == 'הנהלה'){
            return workers.value.filter(worker => worker.category == 'הנהלה')
        }
    }))
    
    const get_workers = async () => {
        const docs = await projectFirestore.collection('Workers').where('active', '==', true).get();
        if(!docs.empty){
            workers.value = docs.docs.map(doc => doc.data())
        }else{
            Swal.fire({
                icon: 'error',
                title: 'שגיאת שרת',
                text: 'לא הצלחנו למשוך את עובדי החברה, צור קשר עם תמיכה טכנית'
            })
            router.push('')
            return
        }
    }
    
    onMounted(() => {
        if(!is_active_shift()){
            slide_pop_error("אין משמרת פעילה כרגע")
            router.push('/work');
        }
        get_workers()
    })

    return{
        filtered_workers, filter, clock_in_shift, get_worker_from_shift
    }
}
}
</script>

<style scoped>
.clock-in-shift{
    width: 100%;
    max-width: 800px;
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.top-bar{
    width: 100%;
    height: 100px;
    background: var(--secondary);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.425);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 24px;
    color: var(--yellow);
    flex-shrink: 0;
    flex-direction: column;
}
.workers-grid{
    width: 100%;
    height: calc(100% - 100px);
    padding: 5px 0 5px 0;
    display: grid;
    grid-template-columns:repeat(auto-fill,minmax(calc(33.33% - 5px), 0.5fr));
    grid-auto-rows: 200px;
    grid-gap: 5px;
    gap: 5px;
    overflow-y: auto;
    overflow: visible;
}
.worker-box{
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--secondary);
    border-radius: 5px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: 0.25s;
    overflow: hidden;
}
.worker-box:hover{
    transform: scale(1.05);
    box-shadow: 0 0 10px var(--success);
    cursor: pointer;
    z-index: 2;
}
.worker-image{
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.worker-image img{
  margin: auto;
  display: block;
  max-width: 100%;
  opacity: 0.57;
  transition: 0.25s;
}
.worker-image img:hover{
    opacity: 1;
}
.worker-box:hover .worker-action{
    opacity:1;
}
.worker-name{
    position: absolute;
    width: 100%;
    height: 25px;
    z-index: 2;
    background: #283046b9;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    color: var(--yellow);
    padding: 0 5px 0 0;
    text-shadow: 0 0 3px black;
}
.worker-action{
    position: absolute;
    top: 2px;
    left: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    opacity: 0;
    transition: 1s;
    color: var(--success);
}
.filter-btns{
    margin-top: 5px;
    width: 100%;
    overflow-x: auto;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}
.filter-btn{
    width: fit-content;
    height: 90%;
    background: var(--main);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 0 10px 0 10px;
    font-size: 18px;
    min-width: 80px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.166);
    cursor: pointer;
}
.worker-in-shift{
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(5, 235, 5, 0.351);
    color: white;
    text-shadow: 0 0 5px black;
}
@media only screen and (max-width: 600px) {
    .workers-grid{
        grid-template-columns:repeat(auto-fill,calc(50% - 2.5px));
        padding: 5px;
        grid-auto-rows: 180px;
    }
}
</style>